<template>
  <div id="feedbacks">
    <van-sticky :offset-top="0">
    <div class="header">
      <van-row gutter="10">
        <van-col span="10" class="header-title" @click="onClickLeft">
          <svg-icon
            :data_iconName="'back-default'"
            :className="'back_icon'"
          />导游评价
        </van-col>
      </van-row>
    </div>
    </van-sticky>
    <div class="feedbacks-wrapper">
      <van-pull-refresh v-model="refreshing" @refresh="onRefresh">
        <van-list
          v-if="list.length > 0"
          v-model="loading"
          :finished="finished"
          finished-text="没有更多了"
          @load="get_guide_feedbacks"
        >
          <div
            class="feedbacks-container"
            v-for="(item, index) in list"
            :key="index"
          >
            <van-row @click="onItemClick(item)">
              <van-col span="24">
                {{
                  item.feedback_content ? item.feedback_content : "暂无评价内容"
                }}
              </van-col>
              <van-col span="24">
                <van-tag
                  style="margin-right: 3px"
                  round
                  type="primary"
                  v-for="(i, index) in item.tags"
                  :key="index"
                  >{{ i }}</van-tag
                >
              </van-col>
              <van-col span="24">
                <van-rate
                  v-model="item.rate_star"
                  :size="25"
                  color="#ffd21e"
                  void-icon="star"
                  void-color="#eee"
                  readonly
                  allow-half
                />
              </van-col>
              <van-col span="24" style="color: #666; font-size: 0.2rem">
                {{ item.creator_name }}
              </van-col>
            </van-row>
          </div>
        </van-list>
        <van-empty v-if="list.length == 0&&loading==false" description="暂无评价" />
      </van-pull-refresh>
    </div>
    <!-- <van-row v-if="list.length == 0">
      <van-col span="24">
        <van-empty image="error" description="暂无评价" />
      </van-col>
    </van-row> -->
  </div>
</template>

<script>
import { Toast } from "vant";
import { Dialog } from "vant";
export default {
  name: "agency-feedbacks",
  data() {
    const _self = this;
    return {
      list: [],
      loading: true,
      finished: false,
      refreshing: false,
      query: {
        page: 1,
        
      },
    };
  },
  methods: {
    onClickLeft() {
      this.$router.go(-1);
    },
    onItemClick(item) {
      this.$router.push({
        path: "/feedbacks/item",
        query: {
          id: item.id,
        },
      });
    },
    async get_guide_feedbacks() {
      const id = this.$route.query.id;
      if (!id) {
        Toast("没有id");
        this.$router.go(-1);
        return false;
      }
      const res = await this.$apis.get_guide_feedbacks(id,this.query);
       if (res.data.length == 25) {
        this.query.page++;

        this.loading = false;
        this.refreshing = false;
      } else {
        this.finished = true;
        this.loading = false;
        this.refreshing = false;
      }
      for (var i = 0; i < res.data.length; i++) {
        this.list.push(res.data[i]);
      }
    //   this.list = res.data;
    },
    onRefresh() {
      // 清空列表数据
      this.list = [];
      this.finished = false;
      this.query.page = 1;
      this.loading = true;
      this.get_guide_feedbacks();
    },
  },
  components: {},
  created() {
    this.onRefresh();
  },
};
</script>

<style scoped>
#feedbacks {
  height: 100vh;
  /* padding-top:10vh; */
}
.header {
  height: 10vh;
  line-height: 10vh;
  padding: 0 0.3rem;
}
.header-title {
  color: #fff;
  font-size: 0.4rem;
  font-weight: bold;
  vertical-align: middle;
}
.feedbacks-wrapper {
  padding: 0.4rem;
  height: 90vh;
  overflow-y: scroll;
  padding-bottom: 6rem;
}
.feedbacks-container {
  padding: 0.3rem;
  background-color: #fff;
  border-radius: 20px;
  border-radius: 20px;
  position: relative;
  font-size: 0.3rem;
  /* height: 80vh; */
  margin-bottom: 10px;
}
.feedbacks-avatar {
  position: absolute;
  top: -0.75rem;
  left: 50%;
  transform: translateX(-50%);
  height: 1.5rem;
  width: 1.5rem;
  border-radius: 50%;
  overflow: hidden;
}
.feedbacks-avatar img {
  width: 100%;
  height: 100%;
}

.feedbacks-text {
  font-size: 14px;
  color: #666666;
  margin-top: 0.2rem;
  text-align: center;
}
.main-text {
  font-size: 16px;
  color: #333333;
  text-align: center;
  font-weight: bold;
}
.star-text {
  text-align: center;
  font-size: 18px;
  margin-top: 30px;
}
.agency-a {
  color: #1989fa;
}
.sub-text {
  font-size: 14px;
  color: #666666;
  margin-top: 5px;
  text-align: center;
}
.qr-container {
  margin-top: 20px;
}
.qr-img {
  background: #ebebeb;
  border-radius: 28px;
  border-radius: 28px;
  width: 160px;
  height: 160px;
  line-height: 160px;
  margin: 0 auto;
  margin-bottom: 10px;
}
.state {
  background: rgba(64, 169, 255, 0.16);
  border-radius: 17px;
  border-radius: 17px;
  height: 34px;
  width: 80px;
  line-height: 34px;
  padding: 0 10px;
  margin: 0 auto;
  margin-top: 10px;
  color: #40a9ff;
  font-size: 14px;
  text-align: center;
}
.feedback-item {
  font-size: 0.3rem;
}
</style>
